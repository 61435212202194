export const turnstileChallenge = (action) => {
  if (typeof turnstile === 'undefined') return

  return new Promise((resolve, reject) => {
    const options = {
      sitekey: turnstile_site_key,
      retry: "never",
      callback: (token) => {
        turnstile.remove()
        resolve(token)
      },
      'error-callback': (error) => {
        console.error(error);
        reject("Turnstile challenge failed. Please try again.")
      }
    }

    if (action) options.action = action
    
    turnstile.render('#cf-turnstile', options)
  });
}
